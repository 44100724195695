const activeColor = "#22356D";
const disableColor = "#9ca5be";
const openMatch = "#EAF956";
const noShow = "#AD00FF";
const pending = "#FF0000";
const lessons = "#E5E5E5";
const events = "#8d98b4";

const grid1 = {
  display: "flex",
  flexDirection: "column",
  columnGap: "5px",
  rowGap: "5px",
  justifyContent: "start",
};

const singleDates = {
  height: "40px",
  alignItems: "center",
  bordedRadius: "8px",
  border: "1px solid #EEEFEE",
  fontFamily: "var(--font-regular)",
  color: activeColor,
  width: "100%",
  marginTop: "2px",
  marginBottom: "2px",
};

const inputStyle = {
  border: "1px solid #EEEFEE",
  padding: "6px 10px",
  color: "#22356DB2",
  fontFamily: "var(--font-regular)",
  width: "300px",
};

const grid2 = {
  paddingLeft: { xs: "0px", sm: "68px" },
  paddingTop: "6px",
  display: "flex",
  flexDirection: "column",
  columnGap: "5px",
  rowGap: "5px",
  justifyContent: "start",
};

const user: any = {
  LOCATION_OWNER: "LOCATION OWNER",
  STAFF_MANAGER: "STAFF MANAGER",
  STAFF: "STAFF",
};

export const dynamicColor: any = {
  Lesson: {
    bgColor: "#FFFEE4",
    color: "#A3A434",
  },
  Booking: {
    bgColor: "#F3F4FE",
    color: "#7E93FF",
  },
  Event: {
    bgColor: "#FEF6F0",
    color: "#F07727",
  },
};

export const coachColors = [
  "#D8D8EB",
  "#CECEE6",
  "#C4C4E0",
  "#BABAD9",
  "#B0B0D3",
  "#A6A6CE",
  "#9C9CC8",
  "#9292C3",
  "#8888BD",
  "#7E7EB8",
  "#7474B2",
  "#6A6AAD",
  "#6060A7",
  "#5656A2",
  "#4C4C9C",
  "#424297",
  "#38497C",
];

export {
  activeColor,
  disableColor,
  openMatch,
  noShow,
  pending,
  lessons,
  events,
  grid1,
  singleDates,
  inputStyle,
  grid2,
  user,
};
