import { useState } from "react";
import { useQuery } from "react-query";
import { apiCaller } from "src/api/ApiCaller";
import { getClubId } from "src/api/CalendarBooking";

export const useGetAllPaymentMethodsByLoc = () => {
  const club_id = getClubId();
  const [location_id, setlocation_id] = useState<number>();
  const [isPayment, setIsPayment] = useState(false);

  const query = useQuery(
    ["PaymentMethodsByLoc", location_id],
    async () => {
      const response = await apiCaller.get(
        `${club_id}/payments/methods/${location_id}`
      );

      return response.data.data;
    },
    {
      enabled: !!location_id,
      staleTime: isPayment ? 0 : 1000 * 60 * 5,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );

  return { setlocation_id, setIsPayment, ...query };
};
