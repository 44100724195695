import {
  Box,
  Dialog,
  DialogContent,
  DialogProps,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { IoMdAdd } from "react-icons/io";
import { MdClose } from "react-icons/md";
import { apiCaller } from "src/api/ApiCaller";
import { getClubId } from "src/api/CalendarBooking";
import MainContext from "src/context/MainContext";

interface PaymentMethodWithId {
  id: number;
  method_name: string;
  available_for_apps: boolean;
  allowed_all_locations: boolean;
  available_for_admin: boolean;
}

type PaymentMethodWithoutId = Omit<PaymentMethodWithId, "id">;

const EditPaymentType = ({
  updatePayments,
  locationID,
  arr,
  text,
  editField,
  open,
  setOpen,
}: {
  text?: string;
  locationID: number;
  arr?: any;
  editField: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  addRegion?: any;
  updatePayments: any;
}) => {
  const { t, i18n } = useTranslation();
  const { setNotification } = useContext(MainContext);
  const currentLanguage = i18n.language;
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("body");
  const { register, control, handleSubmit, reset, watch } = useForm({
    defaultValues: {
      payment_methods: [
        {
          method_name: "",
          allowed_all_locations: false,
          available_for_apps: false,
          available_for_admin: false,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "payment_methods",
  });

  const onSubmit = async (data: any) => {
    const formattedData = data.payment_methods.map((method: any) => ({
      ...method,
      method_name: method.method_name.toLowerCase(), // Convert method_name to lowercase
    }));
    try {
      let club_id = getClubId();
      let res = await apiCaller.post(
        `${club_id}/payments/methods/${locationID}`,
        { payment_methods: formattedData }
      );
      setNotification({
        message: res.data.message,
        isShow: true,
        subMessage: "",
        type: "success",
      });
      console.log(res);
      updatePayments();
      setOpen(false);
    } catch (error: any) {
      setNotification({
        message: error.response.data.message,
        isShow: true,
        subMessage: "",
        type: "error",
      });
      console.log(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (arr.length > 0) {
      const removeIdField = (
        methods: PaymentMethodWithId[]
      ): PaymentMethodWithoutId[] => {
        return methods.map(({ id, ...rest }) => rest);
      };

      const paymentMethodsWithoutId = removeIdField(arr);
      reset({ payment_methods: paymentMethodsWithoutId });
    } else {
      reset({
        payment_methods: [
          {
            method_name: "",
            allowed_all_locations: false,
            available_for_admin: false,
          },
        ],
      });
    }
  }, [open, arr]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={scroll}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        ".MuiDialog-paper": {
          width: {
            xs: "auto",
            sm:
              currentLanguage === "ru"
                ? "1000px !important"
                : "900px !important",
          },
          maxWidth: { xs: "auto", sm: "1020px !important" },
        },
      }}
    >
      <DialogContent>
        <Box padding={2}>
          <Typography
            sx={{ fontFamily: "var(--font-semibold)", color: "#22356D" }}
          >
            {t("common.Payment Settings")}
          </Typography>
          <Box
            sx={{
              ">p": {
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                alignItems: "center",
              },
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 1,
            }}
            marginTop={2}
          >
            <Typography>{t("common.Payment Methods")}</Typography>
            <Typography
              gap={1}
              alignItems={"center"}
              sx={{
                color: "#22356DB2 !important",
                fontSize: "14px",
                display: "flex",
                flexDirection: "row",
              }}
              onClick={() =>
                append({
                  method_name: "",
                  allowed_all_locations: false,
                  available_for_apps: false,
                  available_for_admin: true,
                })
              }
            >
              {t("common.Add payment method")}
              <IoMdAdd style={{ marginTop: "-5px" }} />
            </Typography>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",
                paddingX: "10px",
                margin: "auto",
                marginY: "15px",
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {fields.map((field, index) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    alignItems: "center",
                    gap: 4,
                  }}
                  key={field.id}
                >
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <input
                      type="text"
                      {...register(`payment_methods.${index}.method_name`)}
                      style={{
                        border: "1px solid #EEEFEE",
                        borderRadius: "7px",
                        padding: "5px 8px",
                        color: "#22356DB2",
                        fontFamily: "var(--font-regular) !important",
                        width: "90%",
                        textTransform: "capitalize",
                      }}
                    />
                    <MdClose
                      style={{ color: "#22356DB2", cursor: "pointer" }}
                      onClick={() => remove(index)}
                    />
                  </Box>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "#22356DB2 !important",
                      }}
                    >
                      {t("common.Available in all locations")}
                    </Typography>
                    <label style={{ marginLeft: "6px" }} className="container">
                      <input
                        type="checkbox"
                        {...register(
                          `payment_methods.${index}.allowed_all_locations`
                        )}
                      />
                      <span className="mark"></span>
                    </label>
                  </Box>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "#22356DB2 !important",
                      }}
                    >
                      {t("common.Available for apps")}
                    </Typography>
                    <label style={{ marginLeft: "6px" }} className="container">
                      <input
                        type="checkbox"
                        {...register(
                          `payment_methods.${index}.available_for_apps`
                        )}
                      />
                      <span className="mark"></span>
                    </label>
                  </Box>
                  <Box display={"flex"} gap={1} alignItems={"center"}>
                    <Typography
                      sx={{
                        fontFamily: "var(--font-regular)",
                        color: "#22356DB2 !important",
                      }}
                    >
                      {t("common.Available for admin")}
                    </Typography>
                    <label style={{ marginLeft: "6px" }} className="container">
                      <input
                        type="checkbox"
                        {...register(
                          `payment_methods.${index}.available_for_admin`
                        )}
                      />
                      <span className="mark"></span>
                    </label>
                  </Box>
                </Box>
              ))}
            </Box>
            <Box display={"flex"} justifyContent={"end"}>
              <button
                type="submit"
                style={{
                  fontFamily: "var(--font-semibold)",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 3px 3px 0px",
                  padding: "7px 30px",
                  borderRadius: "7px",
                  color: "white",
                  background: "#22356D",
                }}
              >
                {t("common.Save")}
              </button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default EditPaymentType;
