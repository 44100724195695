import { apiCaller } from "./ApiCaller";
import { getClubId } from "./CalendarBooking";
import { useQuery } from "react-query";
import { useState } from "react";

// get customer details by customer id
export const GetCustomerDetailsByID = async (customer_id: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/customers/settings/${customer_id}`
    );
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

interface playerSwap {
  player1Id: number;
  player2Id?: number;
  targetPositionId?: number;
}
// update player position
export const UpdateCustomerPosition = async (
  service_id: number,
  apiData: playerSwap
) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/calendars/swap-position/${service_id}`,
      apiData
    );
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};
// udpate is customer blocked
export const UpdateISCustomerBlocked = async (
  customer_id: any,
  ApiData: any
) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.patch(
      `${club_id}/customers/block/${customer_id}`,
      ApiData
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// usdate customer settings by customer id
export const updateCustomerSettingById = async (
  customer_id: any,
  ApiData: any
) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.patch(
      `${club_id}/customers/settings/${customer_id}`,
      ApiData
    );
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// update customer's customer settings
export const updateCustomCustomer = async (ApiData: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/customer-fields/records`,
      ApiData
    );
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// get access token of customer
export const GetCustomerToken = async (customer_id: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/customers/accessToken/${customer_id}`
    );
    return response?.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// update customer by id
export const updateCustomerById = async (customer_id: any, Apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/customers/${customer_id}`,
      Apidata
    );
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// update customer's level by club
export const updateCustomerLevelByClub = async (Apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.patch(
      `${club_id}/admins/level`,
      Apidata
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// get level by club
export const useGetLevelByClub = () => {
  let club_id = getClubId();
  return useQuery(
    ["GetLevelByClub"],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/admins/club-level`);
      return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: true }
  );
};

// update customer's level by customer
export const updateCustomerLevel = async (customer_id: any, Apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/customers/customer-level/${customer_id}`,
      Apidata
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// get customer's level by customer id
export const GetCustomerLevel = async (customer_id: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/customers/sports-level/${customer_id}`
    );
    return response?.data?.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// get customer level by sport name
export const GetCustomerLevelBySport = async (
  customer_id: number,
  sport_name: string
) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.get(
      `${club_id}/customers/customer-levels/${customer_id}?sport_name=${sport_name}`
    );
    return response?.data?.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// update customer's column sequence
export const updateColumnSequence = async (Apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.patch(
      `${club_id}/customer-fields/sequence`,
      Apidata
    );
    return true;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// get customer's all booking information
export const GetBookingOfCustomer = async (customer_id: any) => {
  let club_id = getClubId();
  try {
    const response = await apiCaller.get(
      `${club_id}/customers/bookings/${customer_id}`
    );
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// make payment of customer
export const MakePaymentOfCustomer = async (apiData: any) => {
  let club_id = getClubId();
  try {
    const response = await apiCaller.post(
      `${club_id}/customers/pending-payments`,
      apiData
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};
// make payment of pending player
export const MakePaymentOfPendingPalyer = async (
  service_id: number,
  isChecked: boolean,
  apiData: any
) => {
  let club_id = getClubId();
  try {
    const response = await apiCaller.post(
      `${club_id}/payments/services/${service_id}?is_send=${isChecked}`,
      apiData
    );
    return response?.data?.message;
  } catch (error: any) {
    throw error?.response?.data?.message;
  }
};

//get custom settings all
export const useGetStatsOfCustomer = () => {
  let club_id = getClubId();
  const [customer_id, setCustomer_id] = useState<number>();
  const query = useQuery(
    ["GetStatsOfCustomer", customer_id],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/customers/stats/${customer_id}`
      );
      return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: !!customer_id }
  );
  return { setCustomer_id, ...query };
};

//get ClubWallet settings all
export const useGetClubWalletOfCustomer = () => {
  let club_id = getClubId();
  const [customer_id, setCustomer_id] = useState<number>();
  const query = useQuery(
    ["GetClubWalletOfCustomer", customer_id],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/customers/wallets/${customer_id}`
      );
      return response.data.data;
    },
    { staleTime: 1000 * 5 * 60, retry: false, enabled: !!customer_id }
  );
  return { setCustomer_id, ...query };
};

// get all payment methods of cusotmer including wallet , membership and others
export const useGetAllPaymentMethodsCustomer = () => {
  let club_id = getClubId();
  const [customer_id, setCustomer_id] = useState<number>();
  const [locations, setLocations] = useState<number>();
  const [serviceID, setServiceID] = useState<number>();
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");
  const [bookingDate, setbookingDate] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const isActive =
    !!customer_id &&
    !!locations &&
    !!serviceID &&
    !!startTime &&
    !!endTime &&
    !!bookingDate
      ? true
      : false;

  const query = useQuery(
    [
      "GetAllPaymentMethodsCustomer",
      serviceID,
      startTime,
      endTime,
      bookingDate,
      isOpen,
      locations,
      customer_id,
    ],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/payments/locations/${locations}/customers/${customer_id}/details?service_booking_id=${serviceID}&start_time=${startTime}&end_time=${endTime}&date=${bookingDate}&is_open_match=${isOpen}`
      );
      return response?.data?.data;
    },
    {
      staleTime: 1000 * 2,
      retry: false,
      enabled: isActive,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    }
  );
  return {
    setCustomer_id,
    setIsOpen,
    setbookingDate,
    setLocations,
    setServiceID,
    setStartTime,
    setEndTime,
    ...query,
  };
};

// delete customer by id
export const DeleteCustomerByID = async (customer_id: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(
      `${club_id}/customers/${customer_id}`
    );
    const data = response.data.message;
    return data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// create a new customer
export const CreateNewcustomer = async (Apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(`${club_id}/customers`, Apidata);
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// edit customer wallet
export const EditCustomerWallet = async (customer_id: number, Apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.patch(
      `${club_id}/customers/wallets/${customer_id}`,
      Apidata
    );
    return response.data.data;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// add custom setting by club
export const AddCustomSetting = async (Apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/customer-fields`,
      Apidata
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// udpate custom settings by club
export const UpdateCustomSetting = async (apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.put(
      `${club_id}/customer-fields`,
      apidata
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// delete custom settings by club
export const DeleteCustomSetting = async (column_name: string) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.delete(
      `${club_id}/customer-fields/${column_name}`
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

// add new customer type
export const AddCustomerType = async (Apidata: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/customers/customer-type`,
      Apidata
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};

//get custom settings by user
export const useGetCustomSettings = () => {
  let club_id = getClubId();
  const [user_id, setUser_Id] = useState<number>();

  const query = useQuery(
    ["getuserCustomSettings", user_id],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/customer-fields/records/${user_id}`
      );
      return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: !!user_id }
  );
  return { setUser_Id, ...query };
};

//get custom settings all
export const useGetAllCustomSettings = () => {
  let club_id = getClubId();
  return useQuery(
    ["AllCustomSettings"],
    async () => {
      const response: any = await apiCaller.get(`${club_id}/customer-fields`);
      return response.data.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: true }
  );
};

//get custom settings all
export const useGetAllCustomSettingsData = () => {
  let club_id = getClubId();
  return useQuery(
    ["GetAllCustomSettingsData"],
    async () => {
      const response: any = await apiCaller.get(
        `${club_id}/customer-fields/records`
      );
      return response.data;
    },
    { staleTime: 1000 * 60 * 5, retry: false, enabled: true }
  );
};

// add manual credits
export const AddManualCredits = async (customer_id: number, apiData: any) => {
  let club_id = getClubId();
  try {
    const response: any = await apiCaller.post(
      `${club_id}/customers/manual-credits/${customer_id}`,
      apiData
    );
    return response;
  } catch (error: any) {
    let err = error.response?.data?.message;
    throw err;
  }
};
