import Box from "@mui/material/Box";
import { t } from "i18next";
import React, { useContext, useEffect, useState } from "react";
import { BsChevronDown } from "react-icons/bs";
import { AddStaffToLocation, useGetAllStaff } from "src/api/ClubAdmin";
import MainContext from "src/context/MainContext";

const common = {
  display: "flex",
  alignItems: "center",
  fontFamily: "var(--font-regular)",
  color: "#22356D",
};

const LocationOwner = ({
  text,
  data,
  location_id,
  setAddUser,
  setOpen,
  adminPop,
  setData,
  updateLocation,
}: {
  text: string;
  data: any;
  location_id: number;
  setAddUser: (addUser: string) => void;
  setOpen: (locationPop: boolean) => void;
  adminPop: (openPop: boolean) => void;
  setData: any;
  updateLocation: any;
}) => {
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [StaffRole, setStaffRole] = useState<any>([]);
  const [FilteredStaff, setFilteredStaff] = useState([]);
  const [AllStaff, SetAllStaff] = useState([]);
  const { data: StaffData, isSuccess, isError } = useGetAllStaff();
  const { setNotification } = useContext(MainContext);

  const handleClick = () => {
    setData({});
    setAddUser(text);
    setOpen(false);
    adminPop(true);
  };

  const handleEditUser = (item: any) => {
    setData(item);
    adminPop(true);
    setOpen(false);
    setAddUser(text);
  };

  //get all Staff
  useEffect(() => {
    if (isSuccess) {
      // const NotAvail = StaffData?.filter((staf:any)=>{
      //     return data?.some((st:any)=>st?.user_id !== staf?.id);
      // })
      SetAllStaff(StaffData);
    } else {
      SetAllStaff([]);
    }
  }, [StaffData, isSuccess, isError]);

  useEffect(() => {
    const filtered = AllStaff?.filter((item: any) => item?.role?.name == text);
    setStaffRole(filtered);
    setFilteredStaff(filtered);
  }, [AllStaff, text]);

  useEffect(() => {
    if (name) {
      const filtered: any = StaffRole?.filter((staff: any) =>
        staff.full_name.toLowerCase().includes(name.toLowerCase())
      );
      setFilteredStaff(filtered);
    } else {
      setFilteredStaff(StaffRole);
    }
  }, [name]);

  useEffect(() => {
    window.addEventListener("click", (event: any) => {
      event.stopPropagation();
      setShow(false);
    });
  }, []);

  const handleOpen = (event: any) => {
    event.stopPropagation();
    setShow((prev) => !prev);
  };

  const handleStaffClick = async (staff: any) => {
    const apiData = {
      userId: staff.id,
      locationId: location_id,
    };
    try {
      const response = await AddStaffToLocation(apiData);
      setNotification({
        isShow: true,
        type: "success",
        message: response.message,
        subMessage: "",
      });
      updateLocation();
    } catch (error: any) {
      setNotification({
        isShow: true,
        type: "error",
        message: error,
        subMessage: "",
      });
    }
  };

  return (
    <Box>
      <Box
        width={"184px"}
        marginTop={1.5}
        position={"relative"}
        onClick={handleOpen}
      >
        <input
          type="text"
          style={{
            width: "100%",
            height: "37px",
            border: "1px solid #EEEEFE",
            borderRadius: "7px",
            fontFamily: "var(--font-regular)",
            color: "#22356D",
          }}
          placeholder={t("common.Add new")}
          onChange={(event) => setName(event?.target.value)}
        />
        <BsChevronDown
          style={{
            position: "absolute",
            top: "12.5px",
            right: "7.5px",
            fontSize: "0.8rem",
          }}
        />
        {show && (
          <Box
            id={"table-person"}
            marginTop={"0px !important"}
            sx={{
              position: "absolute",
              top: "37px",
              left: "0px",
              right: "0px",
              background: "white",
              borderRadius: "7px",
              width: "200px",
              maxHeight: "150px",
              overflowY: "auto",
            }}
            boxShadow={"0px 4px 4px 0px #0000001A"}
          >
            <Box
              onClick={() => handleClick()}
              sx={{
                background: "#889AB61A",
                fontFamily: "var(--font-regular)",
                color: "#22356D",
                padding: "7px 10px",
              }}
            >
              {" "}
              + {t("common.Add new")}
            </Box>
            {FilteredStaff?.map((item: any) => (
              <Box
                sx={{
                  width: "100%",
                  background: "#889AB61A",
                  height: "37px",
                  padding: "7px 10px",
                  fontFamily: "var(--font-regular)",
                  color: "#22356D",
                  "&:hover": {
                    background: "#22356D",
                    color: "white",
                    borderRadius: "7px",
                  },
                }}
                onClick={() => handleStaffClick(item)}
              >
                {item?.full_name}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box
        id="table-person"
        sx={{
          minHeight: "77px",
          maxHeight: "463px",
          marginTop: "11px !important",
        }}
        className="background-dull"
      >
        {data?.map((item: any, index: number) => (
          <Box
            sx={{
              ...common,
              fontFamily: "var(--font-regular)",
              color: "#22356D",
              padding: "8px 10px",
              height: "77px",
            }}
            justifyContent={"space-between"}
            key={item.email}
            onClick={() => handleEditUser(item)}
          >
            <Box sx={{ ...common }} gap={1.5}>
              <Box
                sx={{
                  padding: "2px 10px",
                  borderRadius: "7px",
                  background: "white",
                }}
              >
                {index + 1}
              </Box>
              <p>{item.user?.full_name}</p>
            </Box>
            <p>{item.user?.email}</p>
          </Box>
        ))}
        {data?.length === 0 && (
          <p
            style={{
              padding: "25px",
              fontFamily: "var(--font-medium)",
              color: "#22356DB2",
            }}
          >
            {t("common.No user added")}
          </p>
        )}
      </Box>
    </Box>
  );
};

export default LocationOwner;
