import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import LocationOwner from "./LocationOwner";
import { useTranslation } from "react-i18next";

const common = {
  display: "flex",
  alignItems: "center",
  fontFamily: "var(--font-regular)",
  color: "#22356D",
};

const textBetween = {
  justifyContent: "space-between",
};

const LocationModal = ({
  index,
  item,
  setLocationPop,
  setData,
  setAddUser,
  filterUsersByRole,
  setOpenPop,
  AllLocationsRefetch,
}: {
  index: number;
  item: any;
  setLocationPop: any;
  setData: any;
  setAddUser: any;
  filterUsersByRole: any;
  setOpenPop: any;
  AllLocationsRefetch: any;
}) => {
  const [value, setValue] = useState<{ [key: number]: string }>({});
  const { t } = useTranslation();

  const handleSelectTab = (value: string, index: number) => {
    setValue((prevState) => ({
      ...prevState,
      [index]: value,
    }));
  };

  return (
    <Box
      sx={{
        background: "white",
        padding: "16px",
        borderRadius: "7px",
        "@media (max-width: 600px)": {
          width: "100%",
        },
      }}
      key={index}
    >
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box
          sx={{
            ...common,
            ...textBetween,
            fontFamily: "var(--font-medium)",
          }}
        >
          <p
            style={{ textDecoration: "underline", textTransform: "capitalize" }}
          >
            {item.location_name}
          </p>
          <p>{item.region?.region_name}</p>
        </Box>
        <Box sx={{ ...common, ...textBetween }}>
          <p>{t("locationmanager.Currency")}</p>
          <p>{item.currency}</p>
        </Box>
        <Box sx={{ ...common, ...textBetween }}>
          <p>{t("locationmanager.Latitude & Longitude")}</p>
          <p style={{ textAlign: "right" }}>{item.georeferenced}</p>
        </Box>
        <Box display={"flex"} justifyContent={"flex-end"}>
          <button
            style={{
              padding: "0px 20px",
              borderRadius: "7px",
              border: "1px solid #EEEEFE",
              boxShadow: "0px 4px 4px 0px #00000012",
              color: "#22356D",
            }}
            onClick={() => {
              setLocationPop(true);
              setData(item);
              setAddUser("Editing");
            }}
          >
            {t("common.Edit")}
          </button>
        </Box>
      </Box>
      <Box>
        <TabContext value={value[index] || "1"}>
          <Box>
            <TabList
              onChange={(event, value) => handleSelectTab(value, index)}
              aria-label="people list"
              TabIndicatorProps={{ sx: { backgroundColor: "#22356D" } }}
              sx={{
                ".MuiTab-root": {
                  paddingBottom: "0px",
                  textTransform: "capitalize",
                  paddingInline: "0px",
                  marginRight: "15px",
                  minWidth: 0,
                  color: "#22356D99",
                },
                ".Mui-selected": {
                  color: "#22356D !important",
                  fontFamily: "var(--font-semibold)",
                },
              }}
            >
              <Tab
                label={`${t("locationmanager.Staff Manager")} [${
                  filterUsersByRole(item.LocationPermissions, "STAFF_MANAGER")
                    .length
                }]`}
                value="1"
                sx={{ fontFamily: "var(--font-regular)" }}
              />
              <Tab
                label={`${t("locationmanager.Staff")} [${
                  filterUsersByRole(item.LocationPermissions, "STAFF").length
                }]`}
                value="2"
                sx={{ fontFamily: "var(--font-regular)" }}
              />
            </TabList>
          </Box>

          {/* ------ staff manager ----- */}
          <TabPanel value={"1"} sx={{ padding: "0px 0" }}>
            <LocationOwner
              updateLocation={AllLocationsRefetch}
              location_id={item?.id}
              text={"STAFF_MANAGER"}
              data={filterUsersByRole(
                item.LocationPermissions,
                "STAFF_MANAGER"
              )}
              setAddUser={setAddUser}
              setOpen={setLocationPop}
              adminPop={setOpenPop}
              setData={setData}
            />
          </TabPanel>
          {/* ------ staff ------ */}
          <TabPanel value="2" sx={{ padding: "0px 0" }}>
            <LocationOwner
              updateLocation={AllLocationsRefetch}
              location_id={item?.id}
              text={"STAFF"}
              data={filterUsersByRole(item.LocationPermissions, "STAFF")}
              setAddUser={setAddUser}
              setOpen={setLocationPop}
              adminPop={setOpenPop}
              setData={setData}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

export default LocationModal;
